import React, { useEffect } from 'react';
import './TelegramChannels.scss';
import fullTimeQr from '../../assets/images/full-time-qr.png';
import partTimeQr from '../../assets/images/part-time-qr.png';
import allJobsQr from '../../assets/images/all-jobs-qr.png';
import { tgChannels } from '../../core/constants/links';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import {
  getCandidateJobsChannelsContent,
} from '../../redux/thunk/webContent';

const TelegramChannels = () => {
  const dispatch = useAppDispatch();
  const { candidateContent } = useAppSelector(state => state.WebContentReducer);

  useEffect(() => {
    dispatch(getCandidateJobsChannelsContent());
  }, []);

  // console.log(candidateContent);

  return (
    <div className='tg-channels'>
      <div className='tg-channels__heading'>
        <span>Join these</span>
        <span className='tg-channels__heading--span'>telegram channels</span>
      </div>
      <ul className='tg-channels__list'>
        <li>
          <img
            className={'tg-channels__list--qr'}
            src={
              candidateContent.all_jobs.qr
                ? `data:image/jpeg;base64,${candidateContent.all_jobs.qr}`
                : allJobsQr
            }
            alt='phone img'
          />
          <a
            href={candidateContent.all_jobs.link || tgChannels.main}
            target='_blank'
            rel='noreferrer'
            className='tg-channels__list--link'
          >
            <div className='tg-channels--icon' />
            All Jobs
          </a>
        </li>
        <li>
          <img
            className={'tg-channels__list--qr'}
            src={
              candidateContent.part_time.qr
                ? `data:image/jpeg;base64,${candidateContent.part_time.qr}`
                : partTimeQr
            }
            alt='phone img'
          />
          <a
            href={candidateContent.part_time.link || tgChannels.partTimeAgency}
            target='_blank'
            rel='noreferrer'
            className='tg-channels__list--link'
          >
            <div className='tg-channels--icon' />
            Part Time Jobs
          </a>
        </li>
        <li>
          <img
            className={'tg-channels__list--qr'}
            src={
              candidateContent.full_time.qr
                ? `data:image/jpeg;base64,${candidateContent.full_time.qr}`
                : fullTimeQr
            }
            alt='phone img'
          />
          <a
            href={candidateContent.full_time.link || tgChannels.fullTimeAgency}
            target='_blank'
            rel='noreferrer'
            className='tg-channels__list--link'
          >
            <div className='tg-channels--icon' />
            Full Time Jobs
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TelegramChannels;
