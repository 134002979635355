import React, { FunctionComponent } from 'react';
import { Container } from '@mui/material';

const UIContainer: FunctionComponent = ({ children }) => (
  <Container
    maxWidth='lg'
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {children}
  </Container>
);

export default UIContainer;
