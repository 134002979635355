import React, { FC } from 'react';
import styles from './_styles.module.scss';
import UIIcon from '../UIIcon/UIIcon';

const UISaveFlag: FC<{
  saved: boolean;
  onClick: () => void;
  color: '#00caba' | '#35435e';
  className?: string;
}> = ({ saved, onClick, color, className }) => {
  return (
    <button className={styles.save_btn} onClick={onClick}>
      <UIIcon
        name={'saveFlag'}
        className={`${styles.flag_svg} ${className || ''}`}
        fill={saved ? color : 'none'}
        width={20}
        height={20}
      />
    </button>
  );
};

export default UISaveFlag;
