import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import useWebContent from '../../core/hooks/useWebContent';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import UIRouterLink from '../../components/UI/UIRouterLink/UIRouterLink';
import { clearSelectedPackages } from '../../redux/reducers/ui/SubscriptionPackagesSlice';
import { tgChannels } from '../../core/constants/links';
import { useParams } from 'react-router-dom';

const CompletionPage: FC = () => {
  const dispatch = useAppDispatch();
  const [webContent] = useWebContent();
  const { candidateContent } = useAppSelector(state => state.WebContentReducer);
  const { selectedAssignmentId, purchaseType } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );
  const { token } = useAppSelector(state => state.userTokenReducer);
  const { originType } = useParams();
  const { subType } = useParams();

  const { REACT_APP_PROD_BOT } = process.env;

  useEffect(() => {
    return () => {
      dispatch(clearSelectedPackages());
    };
  }, [purchaseType, selectedAssignmentId]);

  const linkButton = () => {
    return originType === 'bot' ? (
      <a
        href={REACT_APP_PROD_BOT}
        target='_blank'
        rel='noreferrer'
        className='tg-channels__list--link'
      >
        <div className='tg-channels--icon' />
        Return to bot
      </a>
    ) : originType === 'web' && purchaseType === 'package' ? (
      <UIRouterLink
        title={webContent.continue_to_post_web}
        goTo={`/employer/post-jobs`}
        className={styles.back_btn}
        replace={true}
      />
    ) : originType === 'web' &&  purchaseType === 'bump' ? (
      <UIRouterLink
        title={webContent.continue_to_bump_web}
        goTo={`/employer/manage-jobs?assignment_id=${selectedAssignmentId}`}
        className={styles.back_btn}
        replace={true}
      />
    ) :  originType === 'web' && purchaseType === 'shortlist' ? (
      <UIRouterLink
        title={webContent.continue_to_shortlist_web}
        goTo={`/employer/manage-jobs?assignment_id=${selectedAssignmentId}`}
        className={styles.back_btn}
        replace={true}
      />
    ) : null;
  };

  return (
    <div className={styles.completion}>
      <h1 className={styles.heading}>{webContent.payment_received_web}</h1>
      {linkButton()}
    </div>
  );
};

export default CompletionPage;
