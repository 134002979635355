import React, { useEffect } from 'react';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import {
  clearErrorMessage,
  closeModal,
} from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import styles from '../_styles.module.scss';
import UIButton from '../../UI/UIButton/UIButton';

const ErrorModalContent: React.FC = () => {
  const { errorMessage } = useAppSelector(state => state.modalDialogReducer);
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(clearErrorMessage());
    },
    [dispatch]
  );

  return (
    <div className={styles.modal}>
      <UIModalDialogHeader heading={'Error message'} />
      <div className={styles.modal_body}>
        <p className={styles.message}>{errorMessage}</p>
        <UIButton
          title={'Close'}
          callback={() => dispatch(closeModal())}
          classModificator={styles.back_btn}
        />
      </div>
    </div>
  );
};

export default ErrorModalContent;
