import React, { FC } from 'react';
import styles from './_styles.module.scss';
import Logo from '../../Logo/Logo';
import { useAppSelector } from '../../../core/hooks/reduxHooks';
import { useWindowSize } from '../../../core/hooks/useWindowSize';
import BurgerMenu from '../../BurgerMenu/BurgerMenu';
import HeaderEmployerNavLinks from '../HeaderEmployerNavLinks/HeaderEmployerNavLinks';
import { breakpointForEmployerHeader } from '../../../core/constants/variables';
import MobileEmployerHeader from './MobileEmployerHeader/MobileEmployerHeader';
// import BurgerMenu from '../BurgerMenu/BurgerMenu';
// import { mobileLandscapeBreakpoint } from '../../core/constants/variables';
// import { useWindowSize } from '../../core/hooks/useWindowSize';

const EmployerHeader: FC = () => {
  // const size = useWindowSize();
  const token = useAppSelector(state => state.userTokenReducer.token);
  const size = useWindowSize();

  const desktopHeader = () => (
    <div className={styles.header_wrap}>
      <header
        className={`${styles.header} ${
          token ? styles.header_auth : styles.header_noauth
        }`}
      >
        <Logo goTo={'/employer'} className={styles.logo} />
        {size.width < breakpointForEmployerHeader && token ? (
          <BurgerMenu routes={'employer'} />
        ) : (
          <HeaderEmployerNavLinks />
        )}
      </header>
    </div>
  );

  return size.width > breakpointForEmployerHeader ? (
    desktopHeader()
  ) : (
    <MobileEmployerHeader />
  );
};

export default EmployerHeader;
