import React from 'react';
import Icon, { IconType } from '../UIIcon/UIIcon';
import style from './_index.module.scss';
import {
  InvoicesOrder,
  InvoicesOriginOrder,
} from '../../../core/models/models';
export interface TableHeader {
  label: string;
  orderId?: InvoicesOriginOrder;
  icon?: IconType;
}

const THead: React.FC<
  React.HTMLProps<HTMLTableSectionElement> & {
    tableHeaders?: TableHeader[];
    order?: InvoicesOrder;
    orderBy?: (value: InvoicesOrder) => void;
  }
> = ({ tableHeaders, order, orderBy, ...rest }) => (
  <thead className={style.thead} {...rest}>
    <tr>
      {tableHeaders?.map(({ label, orderId, icon }, idx) => (
        <th
          key={idx}
          onClick={() =>
            orderId
              ? orderBy?.(`${order === orderId ? '-' : ''}${orderId}`)
              : undefined
          }
        >
          {label}
          {icon && (
            <Icon
              name={icon}
              className={order === `-${orderId}` ? style.reversed : undefined}
            />
          )}
        </th>
      ))}
      {rest.children || null}
    </tr>
  </thead>
);

const TBody: React.FC<React.HTMLProps<HTMLTableSectionElement>> = ({
  children,
  ...rest
}) => (
  <tbody className={style.tbody} {...rest}>
    {children}
  </tbody>
);

const Table: React.FC<React.HTMLProps<HTMLTableElement>> & {
  Head: typeof THead;
  Body: typeof TBody;
} = ({ children, ...rest }) => {
  return (
    <table
      {...rest}
      className={`${style.table}${rest?.className ? ` ${rest.className}` : ''}`}
    >
      {children}
    </table>
  );
};

Table.Head = THead;
Table.Body = TBody;

export default Table;
