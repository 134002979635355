import React, { FC } from 'react';
import { InputLabel, InputLabelProps, styled } from '@mui/material';

const ThemeInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '19px',
  letterSpacing: '0.8px',
}));

const UIInputLabel: FC<InputLabelProps> = ({ children, ...rest }) => {
  return (
    <ThemeInputLabel shrink={true} {...rest}>
      {children}
    </ThemeInputLabel>
  );
};

export default UIInputLabel;
