import React, { FC, useEffect } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import Icon, { IconType } from '../UIIcon/UIIcon';
import styles from './_styles.module.scss';

export interface UINavLinkProps extends NavLinkProps {
  title: string;
  icon: IconType;
  role: 'employer' | 'candidate' | 'admin';
  activePaths?: string[];
}

const UINavLink: FC<UINavLinkProps> = ({
  activePaths,
  children,
  to,
  title,
  icon,
  className,
  end,
  role,
  ...rest
}) => {
  const { pathname } = useLocation();
  const match = useMatch({
    path: to as string,
    end: end ?? (to as string).length === 1,
  });

  const activeClass = () => {
    return match ||
      (activePaths && !!~activePaths.findIndex(path => pathname === path))
      ? `${styles[`active_${role}`]}`
      : '';
  };

  return (
    <NavLink
      {...rest}
      end={end}
      to={to}
      className={`${activeClass()} ${styles.custom_link} ${className || ''}`}
    >
      <Icon name={icon} />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <span about={title}>{title}</span>
      {children}
    </NavLink>
  );
};

export default UINavLink;
