import React from 'react';
import UIButtonNavLink from '../../components/UI/UINavLink/UIButtonNavLink';
import style from './_index.module.scss';
import Tabs from '../../components/Tabs/Tabs';

interface NavigationLayoutProps {
  navigationList: {
    title: string;
    goTo: string;
    end?: boolean;
  }[];
  children: React.ReactNode;
}

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  navigationList,
  children,
}) => {
  return (
    <>
      <nav className={style.navigationMenu}>
        {navigationList.map(link => (
          <UIButtonNavLink key={link.title + link.goTo} {...link} />
        ))}
      </nav>
      {children}
    </>
  );
};

export default NavigationLayout;
