import React from 'react';
import styles from './_styles.module.scss';
import EmployerHeader from '../../components/Employer/EmployerHeader/EmployeeHeader';
import Footer from '../../components/Footer/Footer';

const EmployerLayout: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => (
  <div className={styles.layout}>
    <div className={styles.layout_content}>
      <EmployerHeader />
      <div className={styles.layout_content_main}>{children}</div>
    </div>
    <Footer />
  </div>
);

export default EmployerLayout;
