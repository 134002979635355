import React, { FC } from 'react';
import './InfoGridCard.scss';
import cn from 'classnames';
import Icon from '../../components/UI/UIIcon/UIIcon';

interface InfoGridCardProps {
  heading?: string;
  logo?:
    | 'info'
    | 'details'
    | 'categories'
    | 'location'
    | 'notification'
    | 'book'
    | 'status';
  position?: 'top' | 'bottom' | '';
  role?: 'candidate' | 'employer' | 'admin';
  className?: string;
}

const InfoGridCard: FC<InfoGridCardProps> = ({
  children,
  className,
  heading,
  logo,
  position,
  role,
}) => {
  return (
    <div
      className={`${cn({
        'info-grid__card': true,
        'info-grid__card--top': position === 'top',
        'info-grid__card--bottom': position === 'bottom',
        'info-grid__card--candidate': role === 'candidate',
        'info-grid__card--employer': role === 'employer',
      })} ${className ? ` ${className}` : ''}`}
    >
      {heading && logo && (
        <div className='info-grid__heading'>
          <Icon name={logo} />
          <span>{heading}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export default InfoGridCard;
