import React, { FC } from 'react';
import styles from './_styles.module.scss';
import PurchaseListItem from './PurchaseListItem';
import { IInvoiceDetails } from '../../../../core/models/models';

const PurchaseList: FC<{ historyData: IInvoiceDetails[] }> = ({
  historyData,
}) => {
  return (
    <ul className={styles.purchase_list}>
      {historyData.map(purchase => {
        return (
          <PurchaseListItem key={purchase.purchase_id} details={purchase} />
        );
      })}
    </ul>
  );
};

export default PurchaseList;
