import React, { FC } from 'react';
import { IInvoiceDetails } from '../../../../core/models/models';

const TableRow: FC<{ data: IInvoiceDetails }> = ({ data }) => {
  return (
    <tr>
      {Object.keys(data).map(key => {
        if (key !== 'purchase_id') {
          return <td key={key}>{data[key as keyof typeof data]} </td>;
        }
      })}
    </tr>
  );
};

export default TableRow;
