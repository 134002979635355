import React, { FC, ChangeEventHandler } from 'react';
import { FormControl, Input, InputAdornment } from '@mui/material';
import './UILogin.scss';
import { ReactComponent as PhoneLogo } from '../../../assets/icons/login-cand-tel.svg';
import UIInputLabelLogin from '../UIInputLabel/UIInputLabelLogin';

interface UILoginPhoneProps {
  error?: boolean;
  value?: string;
  color: 'primary' | 'secondary';
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const UILoginPhone: FC<UILoginPhoneProps> = ({
  error = false,
  color,
  ...rest
}) => {
  return (
    <div className='auth__phone'>
      <FormControl variant='standard'>
        <UIInputLabelLogin color={color} htmlFor={'auth-phone'}>
          Phone
        </UIInputLabelLogin>
        <div className='auth__phone--input'>
          <div className='auth__phone--code'>
            <span>+65</span>
          </div>
          <Input
            required={true}
            id='auth-phone'
            aria-describedby='auth-phone'
            type='number'
            color={color}
            error={error}
            inputProps={{
              type: 'tel',
              pattern: '[0-9]*',
              'aria-label': 'auth-phone',
              style: {
                color: '#35435E',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '25px',
              },
            }}
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: 400,
            }}
            endAdornment={
              <InputAdornment position='end' sx={{ paddingRight: '5px' }}>
                <PhoneLogo />
              </InputAdornment>
            }
            {...rest}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default UILoginPhone;
