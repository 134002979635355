import React from 'react';
import UIModalDialogHeader from '../../UI/UIModalDialog/UIModalDialogHeader';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/reduxHooks';
import styles from '../_styles.module.scss';
import UIButton from '../../UI/UIButton/UIButton';
import { closeModal } from '../../../redux/reducers/ui/ModalDialogSlice';

const InfoModalContent: React.FC = () => {
  const { infoMessage } = useAppSelector(state => state.modalDialogReducer);
  const dispatch = useAppDispatch();

  return (
    <div
      className={`${styles.modal}${
        infoMessage.title === 'email verifying' ? ` ${styles.modal_email}` : ''
      }`}
    >
      <UIModalDialogHeader heading={infoMessage.title} />
      <div className={styles.modal_body}>
        <p className={styles.message}>{infoMessage.message}</p>
        <UIButton
          title={'Close'}
          callback={() => dispatch(closeModal())}
          classModificator={styles.back_btn}
        />
      </div>
    </div>
  );
};

export default InfoModalContent;
