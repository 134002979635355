import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { useAppDispatch } from '../../core/hooks/reduxHooks';
import { drawerSlice } from '../../redux/reducers/ui/DrawerSlice';

type BurgerMenuProps = {
  routes: 'candidate' | 'employer';
};

const BurgerMenu: FC<BurgerMenuProps> = ({ routes }) => {
  const dispatch = useAppDispatch();
  const { openDrawer } = drawerSlice.actions;

  return (
    <button
      type='button'
      className={`${styles.burger} ${
        routes === 'employer' ? styles.burger_emplr : ''
      }`}
      onClick={() => dispatch(openDrawer())}
    >
      <span className={styles.burger_line} />
      <span className={`${styles.burger_line} ${styles.burger_line__mg}`} />
      <span className={styles.burger_line} />
    </button>
  );
};

export default BurgerMenu;
