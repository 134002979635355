import React, { FC } from 'react';
import './UIFilterButton.scss';
import cn from 'classnames';
import { useAppSelector } from '../../../core/hooks/reduxHooks';

type UIButtonFilterProps = {
  disabled?: boolean;
  title: string;
  classModifier?: string;
  callback: () => void;
  filterKey: 'all' | 'applied' | 'saved';
};

const UIFilterButton: FC<UIButtonFilterProps> = ({
  title,
  disabled = false,
  callback,
  filterKey,
}) => {
  const tab = useAppSelector(state => state.candidateAssignments.tab);

  // console.log(filter);
  // console.log(filterKey);

  return (
    <button
      type='button'
      className={cn({
        Button: true,
        Button__filter: true,
        'Button__filter--inactive': filterKey !== tab,
      })}
      disabled={disabled}
      onClick={() => callback()}
    >
      <p className='button__filter--title'>{title}</p>
    </button>
  );
};

export default UIFilterButton;
