import React from 'react';
import styles from './_styles.module.scss';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../core/hooks/reduxHooks';

const Loader = () => {
  return (
    <div className={styles.loader}>
      <CircularProgress className={styles.circular} />
    </div>
  );
};

export default Loader;
