import React, { FC } from 'react';
import UIFilterButton from '../../UI/UIFilterButton/UIFilterButton';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';
import styles from './_styles.module.scss';
import { setTab } from '../../../redux/reducers/api/CandidateAssignmentsSlice';

const SearchWorkFilters: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.work_filters}>
      <UIFilterButton
        title={'All jobs'}
        callback={() => {
          dispatch(setTab('all'));
        }}
        filterKey={'all'}
        classModifier={styles.btn}
      />
      <UIFilterButton
        title={'Applied'}
        callback={() => {
          dispatch(setTab('applied'));
        }}
        filterKey={'applied'}
        classModifier={styles.btn}
      />
      <UIFilterButton
        title={'Saved'}
        callback={() => {
          dispatch(setTab('saved'));
        }}
        filterKey={'saved'}
        classModifier={styles.btn}
      />
    </div>
  );
};

export default SearchWorkFilters;
