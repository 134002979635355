import React from 'react';
import ReactDatePiker, { ReactDatePickerProps } from 'react-datepicker';
import Icon from '../UIIcon/UIIcon';
import styles from './_styles.module.scss';

const UiDatePicker: React.FC<ReactDatePickerProps> = ({
  dateFormat,
  showYearDropdown,
  scrollableYearDropdown,
  yearDropdownItemNumber,
  maxDate,
  ...rest
}) => {

    // const ReactDatePiker: any = DatePicker;

  return (
    <label className={styles.field}>
      <ReactDatePiker
        {...rest}
        dateFormat={dateFormat ?? 'dd/MM/yyyy'}
        showYearDropdown={showYearDropdown ?? true}
        scrollableYearDropdown={scrollableYearDropdown ?? true}
        yearDropdownItemNumber={yearDropdownItemNumber ?? 100}
        maxDate={maxDate ?? new Date()}
      />
      <div className={styles.calendar_logo}>
        <Icon name='calendar' />
      </div>
    </label>
  );
};

export default UiDatePicker;
