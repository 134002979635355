import React, { FC } from 'react';
import styles from './_styles.module.scss';
import Icon from '../UIIcon/UIIcon';

interface TGForwardLinkProps {
  urlTg: string;
  title: string;
}

const TGForwardLink: FC<TGForwardLinkProps> = ({ urlTg, title }) => {
  return (
    <a className={styles.tg_link} target='_blank' rel='noreferrer' href={urlTg}>
      <Icon name='telegram' className={styles.tg_icon} />
      {title}
    </a>
  );
};

export default TGForwardLink;
