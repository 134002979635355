import { createSlice } from '@reduxjs/toolkit';

interface DrawerState {
  isOpened: boolean;
}

const initialState: DrawerState = {
  isOpened: false,
};

export const drawerSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    openDrawer: state => {
      state.isOpened = true;
    },
    closeDrawer: state => {
      state.isOpened = false;
    },
    /* eslint-enable no-param-reassign */
  },
});

export default drawerSlice.reducer;
