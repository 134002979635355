import React, { useEffect, useState } from 'react';
import UIButton from '../UIButton/UIButton';
import style from './_index.module.scss';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  onSearch?: (value: string) => void;
  label?: string;
  labelClassName?: string;
  containerClassName?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label = '',
  labelClassName,
  containerClassName = '',
  onSearch,
  value,
  onChange,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState(value ?? '');

  const onInputKeyUp = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      onSearch?.(localValue);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(event);
    } else {
      setLocalValue(event.target.value);
    }
  };

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  return (
    <div
      className={`${style.wrap}${
        containerClassName ? ` ${containerClassName}` : ''
      }`}
    >
      <div>
        {label && (
          <label htmlFor={rest?.id || 'searchInput'} className={labelClassName}>
            {label}
          </label>
        )}
        <input
          {...rest}
          id={rest?.id || 'searchInput'}
          value={localValue}
          onChange={onInputChange}
          onKeyUp={onInputKeyUp}
          placeholder='Enter the keyword'
        />
      </div>
      <UIButton
        title='Search'
        disabled={rest?.disabled}
        callback={() => onSearch?.(localValue)}
      />
    </div>
  );
};

export default SearchInput;
