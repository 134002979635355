import React, { FC, useEffect, useMemo } from 'react';
import styles from './_styles.module.scss';
import StripeContainer from '../PaymentForm/StripeContainer';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import PriceCardBumpShortlist from '../PriceCardBumpShortlist/PriceCardBumpShortlist';
import { useRouter } from '../../../../core/hooks/useRouter';
import { getSubscriptionBumpOrShortlist } from '../../../../redux/thunk/subscription';

interface QueryParams {
  origin: string;
  item: string;
  item_id: string;
  user_id: string;
  assignment_id: string;

}
const PaymentFormBumpShortlist: FC = () => {
  const {
    selectedBumpPackage,
    selectedShortlistPackage,
    selectedAssignmentId,
  } = useAppSelector(state => state.subscriptionPackagesReducer);
  const { user_id } = useAppSelector(state => state.userTokenReducer);

  const { location } = useRouter();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const getQueryParam = (key: keyof QueryParams): string | null =>
    searchParams.get(key);

  const originType = getQueryParam('origin') || '';
  const itemId = getQueryParam('item_id') || '';
  const itemType = getQueryParam('item');
  const userId = user_id !== null ? user_id : getQueryParam('user_id') ?? '';
  const assignment_id = getQueryParam('assignment_id') ?? selectedAssignmentId;

  useEffect(() => {
    dispatch(getSubscriptionBumpOrShortlist(itemId));
  }, [itemId]);

  const paymentForm = useMemo(() => {
    const subType =
      itemType === 'bump'
        ? {
            repost: {
              quantity: selectedBumpPackage?.amount || 0,
              item_id: selectedBumpPackage?.package_id || '',
            },
          }
        : itemType === 'shortlist'
        ? {
            shortlist: {
              quantity: selectedShortlistPackage?.amount || 0,
              item_id: selectedShortlistPackage?.package_id || '',
            },
          }
        : {};

    return {
      user_id: userId,
      ...subType,
      assignment_id: assignment_id,
      origin: originType,
    };
  }, [selectedBumpPackage, userId]);

  if (itemId && !selectedBumpPackage && !itemType) {
    return <Navigate to={`/employer/price`} state={{ from: location }} />;
  }

  return (
    <div className={styles.payment}>
      {itemId && selectedBumpPackage && itemType && (
        <PriceCardBumpShortlist
          id={selectedBumpPackage.package_id}
          type={itemType}
          price={selectedBumpPackage.price}
          assignmentId={assignment_id}
        />
      )}
      <div className={styles.payment_stripe}>
        {selectedShortlistPackage?.amount !== 0 && <StripeContainer paymentIntentForm={paymentForm} />}
      </div>
    </div>
  );
};

export default PaymentFormBumpShortlist;
