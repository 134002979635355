import qs from 'qs';
import {
  AddAdminAssignmentPayload,
  AddAdminAssignmentResponse,
  AdminAssignment,
  AdminAssignmentProposalsParams,
  AdminAssignmentProposalsResponse,
  AdminAssignmentsParams,
  AdminAssignmentsResponse,
  AdminAssignmentsType,
  AdminInvoicesParams,
  AdminInvoicesResponse,
  ApproveAdminAssignmentResponse,
  AssignmentFilters,
  CreateAdminInvoicePayload,
  IApplyJobInfo,
  IAssignmentDetails,
  IAvailableWorkDetails,
  IAvailableWorks,
  IAvailableWorksParams,
  IInvoicesData,
  IManageJobsParams,
  InvoicesParams, IPackage,
  IPendingApprovalJobs,
  IReviewCandidate,
  IReviewCandidatesResult,
  ISubscriptionBumpPackages,
  ISubscriptionPackages,
  ISubscriptionShortlistPackages,
  IUserLogin,
  IUserManageJobs,
  IUserRegister,
  IUserToken,
  IUserVerify,
  PostUserJobInformation,
  RejectAdminAssignmentResponse,
  VerifyNumberPayload,
} from '../models/models';
import { apiClient } from '../services/axios';
import { AdminAssignmentsForm } from '../../redux/reducers/api/AdminAssignmentSlice';
import axios from 'axios';
import {
  verifyUserEmailUnsubscribeCandidates,
  verifyUserEmailUnsubscribeJobs,
} from '../../redux/thunk/profile';

export const API = {
  async getOpenAssignments(payload?: IAvailableWorksParams) {
    return apiClient.get<IAvailableWorks>(
      '/assignments/open',
      payload
        ? {
            params: payload,
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' });
            },
          }
        : undefined
    );
  },
  async getAssignmentDetails(assignment_id: string) {
    return apiClient.get<IAvailableWorkDetails>(
      `/assignments?assignment_id=${assignment_id}`
    );
  },
  async getSavedAssignments(payload: IAvailableWorksParams) {
    return apiClient.get<IAvailableWorks>(
      '/assignments/saved',
      payload
        ? {
            params: payload,
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' });
            },
          }
        : undefined
    );
  },
  async getAppliedAssignments(payload: IAvailableWorksParams) {
    return apiClient.get<IAvailableWorks>(
      '/assignments/applied',
      payload
        ? {
            params: payload,
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' });
            },
          }
        : undefined
    );
  },
  // save assignments
  async getAssignmentFilters() {
    return apiClient.get<AssignmentFilters>('/assignments/filters');
  },

  async saveAssignment(payload: string) {
    return apiClient.post('/assignments/save', { assignment_id: payload });
  },

  async deleteSaveAssignment(payload: string) {
    return apiClient.post('/assignments/save/delete', {
      assignment_id: payload,
    });
  },

  async getApplyPosterInfo(payload: string) {
    return apiClient.get<IApplyJobInfo>(
      `/assignments/apply?assignment_id=${payload}`
    );
  },

  async postApplyJobCandidate(payload: FormData) {
    return apiClient.post('/assignments/apply', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // profile
  async getCandidateProfileInfo() {
    return apiClient.get('/profile/update/candidate');
  },
  async getEmployerProfileInfo() {
    return apiClient.get('/profile/update/employer');
  },
  async updateEmployerProfileInfo(payload: FormData) {
    return apiClient.post('/profile/update/employer', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async updateCandidateProfileInfo(payload: FormData) {
    return apiClient.post('/profile/update/candidate', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async updatePhoneNumber(payload: { hp_number: string }) {
    return apiClient.post('/profile/update/hp_number', payload);
  },
  async verifyEmailHandler(payload: { email: string }) {
    return apiClient.post('/auth/send-verification-email', payload);
  },
  async verifyUpdatePhoneNumber(payload: { code: string; hp_number: string }) {
    return apiClient.post('/profile/update/hp_number/validate', payload);
  },
  async getResume() {
    return apiClient.get('/profile/resume', { responseType: 'arraybuffer' });
  },
  async verifyUserEmail(token: string) {
    const { REACT_APP_BASE_API_URL } = process.env;

    return axios.post(`${REACT_APP_BASE_API_URL}/email/verify/${token}`);
  },
  async verifyUserEmailUnsubscribeJobs(token: string) {
    const { REACT_APP_BASE_API_URL } = process.env;

    return axios.post(
      `${REACT_APP_BASE_API_URL}/email/unsubscribe/jobs/${token}`
    );
  },
  async verifyUserEmailUnsubscribeCandidates(token: string) {
    const { REACT_APP_BASE_API_URL } = process.env;

    return axios.post(
      `${REACT_APP_BASE_API_URL}/email/unsubscribe/applicants/${token}`
    );
  },
  async getProfileStatus() {
    return apiClient.get<{ complete: boolean; required_fields: string[] }>(
      '/auth/candidate'
    );
  },
  async deleteUserProfile() {
    return apiClient.delete('/profile/delete');
  },

  async userLogin(data: IUserLogin) {
    return apiClient.post<IUserToken>(
      `/auth/login`, data
    );
  },
  async userRegister(data: IUserRegister) {
    return apiClient.post<IUserToken>(
      `/auth/register?&hp_number=${data.hp_number}&password=${data.password}${
        data.chosen_notifications
          ? `&chosen_notifications=${data.chosen_notifications}`
          : ''
      }`
    );
  },
  async verifyUserRegister(payload: IUserVerify) {
    return apiClient.post<IUserToken>('/auth/verify', payload);
  },
  async resetPasswordFromLogin(hp_number: string) {
    return apiClient.post('/auth/reset', { hp_number });
  },
  async verifyResetLoginPasswordUpdate(payload: {
    hp_number: string;
    password: string;
    code: string;
  }) {
    return apiClient.post('/auth/set', payload);
  },
  async resetPasswordFromProfile(payload: { password: string; code: string }) {
    return apiClient.post('/auth/change', payload);
  },
  async verifyProfilePasswordUpdate() {
    return apiClient.post('/auth/otp');
  },
  async resendUserRegisterVerifyCode(payload: {
    hp_number: string;
    password: string;
  }) {
    return apiClient.post<{ otp_expiration_timestamp: number }>(
      '/auth/retry',
      payload
    );
  },
  async resendAdminVerifyCode(payload: {
    hp_number: string;
    password: string;
  }) {
    return apiClient.post<{ otp_expiration_timestamp: number }>(
      '/auth/retry/admin',
      payload
    );
  },
  async verifyAdminLogin(payload: VerifyNumberPayload) {
    return apiClient.post<IUserToken>('/auth/verify/admin', payload);
  },
  async refreshToken() {
    return apiClient.get('/auth/refresh');
  },
};

// Post a job from employer

export const employerAPI = {
  async getSubscriptionPackages() {
    return apiClient.get<ISubscriptionPackages>('/subscription/packages');
  },
  async getSubscriptionPackage(package_id: string) {
    return apiClient.get<IPackage>(`/subscription/package?package_id=${package_id}`);
  },
  async getSubscriptionBumpShortlist(package_id: string) {
    return apiClient.get<ISubscriptionBumpPackages>(`/subscription/package?package_id=${package_id}`);
  },
  async getSubscriptionBumpPackages() {
    return apiClient.get<ISubscriptionBumpPackages[]>('/subscription/reposts');
  },
  async getSubscriptionShortlistPackages() {
    return apiClient.get<ISubscriptionShortlistPackages[]>(
      '/subscription/shortlists'
    );
  },
  async getPosterAssignmentJob() {
    return apiClient.get('/post_assignment/new');
  },
  async newPostAssignmentJob(data: PostUserJobInformation) {
    return apiClient.post('/post_assignment/new', data);
  },
  async getPostDrafts(page_number: number) {
    return apiClient.get(
      `/post_assignment/drafts?page_number=${page_number}&page_size=6`
    );
  },
  async getPendingApprovalPosts(page_number: number) {
    return apiClient.get<IPendingApprovalJobs>(
      `/assignments/on_review?all_types_of_jobs=true&page_number=${page_number}&page_size=6`
    );
  },
  async getAssignmentJobDetails(assignment_id: string) {
    return apiClient.get<IAssignmentDetails>(
      `/assignments?assignment_id=${assignment_id}`
    );
  },
  async getManageAssignmentPosts(data: IManageJobsParams) {
    return apiClient.get<IUserManageJobs>(
      `/manage_assignment/all?order_by=${data.order_by}&page_number=${
        data.page_number
      }&page_size=${data.page_size || 6}`
    );
  },

  async newEditedPostAssignment(
    data: PostUserJobInformation,
    assignmentId: string
  ) {
    return apiClient.post(
      `/manage_assignment/update/${assignmentId}/user`,
      data
    );
  },

  async newDraftAssignmentJob(draft: PostUserJobInformation) {
    return apiClient.post('/post_assignment/draft', draft).then(({ data }) => {
      return data;
    });
  },
  async repostAssignmentJob(assignmentId: string) {
    return apiClient
      .post(`/manage_assignment/repost/${assignmentId}`)
      .then(({ status, data }) => {
        if (status === 200) {
          return data;
        }
      });
  },
  async getAssignmentJobReviewCandidates(
    assignment_id: string,
    orderBy: string,
    page: number
  ) {
    return apiClient
      .get<IReviewCandidatesResult>(
        `/proposals/${assignment_id}?order_by=${orderBy}&page_number=${page}&page_size=10`
      )
      .then(({ status, data }) => {
        return data;
      });
  },
  async getAssignmentJobReviewCandidateInfo(id: string) {
    return apiClient
      .post<IReviewCandidate>(`/proposals/shortlist?proposal_id=${id}`)
      .then(({ data }) => {
        // console.log(data);

        return data;
      });
  },
  async getEmployerInvoices(params: InvoicesParams) {
    return apiClient.get<IInvoicesData>(`/invoices`, {
      params,
    });
  },
};

export const adminAPI = {
  async getAdminInvoices(params: AdminInvoicesParams) {
    return apiClient
      .get<AdminInvoicesResponse>('/invoices/admin', { params })
      .then(({ status, data }) => {
        if (status === 200) {
          return data;
        }

        // console.log(data);
        throw new Error(`Unhandled response with status: ${status}`);
      });
  },
  async createAdminInvoice(payload: CreateAdminInvoicePayload) {
    return apiClient
      .post('/invoices/builder', payload, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => data);
  },
  async getCreateAdminInvoicePoster() {
    return apiClient
      .get<{ GST: number }>('/invoices/builder')
      .then(({ data }) => data);
  },
  // async getAdminAssignmentProposals(
  //   assignmentId: string,
  //   params?: AdminAssignmentProposalsParams
  // ) {
  //   return apiClient
  //     .get<AdminAssignmentProposalsResponse>(
  //       `/proposals/${assignmentId}/admin`,
  //       { params }
  //     )
  //     .then(({ data }) => data);
  // },
  async getAdminAssignmentById(assignmentId: string) {
    return apiClient
      .get<AdminAssignment>(`/manage_assignment/view/${assignmentId}/admin`)
      .then(({ data }) => data);
  },
  async getAdminAssignments(
    assignmentsType: AdminAssignmentsType,
    params: AdminAssignmentsParams
  ) {
    return apiClient
      .get<AdminAssignmentsResponse>(
        `/manage_assignment/${assignmentsType}/admin`,
        { params }
      )
      .then(({ data }) => data);
  },
  async getAdminAssignmentConfig() {
    return apiClient
      .get<{ expiration_date: string }>('/post_assignment/admin/new')
      .then(({ data }) => data);
  },
  async addAdminAssignment(payload: AddAdminAssignmentPayload) {
    const newPayload = Object.entries(payload).reduce(
      (acc, [key, value]) =>
        value
          ? {
              ...acc,
              [key]: value,
            }
          : acc,
      {}
    );

    return apiClient
      .post<AddAdminAssignmentResponse>(
        '/post_assignment/admin/new',
        newPayload
      )
      .then(({ data }) => data);
  },
  updateAdminAssignment(
    { assignmentId, ...rest }: AdminAssignmentsForm,
    bannedContent: boolean
  ) {
    const url = bannedContent
      ? `/manage_assignment/confirm_approve_content/${assignmentId}`
      : `/manage_assignment/update/${assignmentId}/admin`;

    return apiClient
      .post(url, {
        ...rest,
      })
      .then(({ data }) => data);
  },
  async approveAdminAssignment(
    assignmentId: string,
    banned?: boolean,
    pending?: boolean
  ) {
    const pendingApprove = `/manage_assignment/post_now/${assignmentId}/admin`;

    return apiClient
      .post<ApproveAdminAssignmentResponse>(
        pending
          ? pendingApprove
          : `/manage_assignment/approve${
              banned ? '_content' : ''
            }/${assignmentId}/admin`
      )
      .then(({ data }) => data);
  },
  async rejectAdminAssignment(assignmentId: string) {
    return apiClient
      .post<RejectAdminAssignmentResponse>(
        `/manage_assignment/reject/${assignmentId}/admin`
      )
      .then(({ data }) => data);
  },
  async deleteAdminAssignment(assignmentId: string) {
    return apiClient
      .post(`/manage_assignment/delete/${assignmentId}/admin`)
      .then(({ data }) => data);
  },
  async getAdminAssignmentJobReviewCandidateInfo(
    id: string,
    orderBy: string,
    page: number
  ) {
    return apiClient
      .get(
        `/proposals/${id}/admin?order_by=${orderBy}&page_number=${page}&page_size=10`
      )
      .then(({ data }) => {
        return data;
      });
  },
  async repostAdminAssignmentJob(assignmentId: string) {
    return apiClient
      .post(`/manage_assignment/repost/${assignmentId}/admin`)
      .then(({ status, data }) => {
        if (status === 200) {
          return data;
        }
      });
  },
  async getProposalResume(proposalId: string) {
    return apiClient.get(`/proposals/resume?proposal_id=${proposalId}`, {
      responseType: 'arraybuffer',
    });
  },
};
