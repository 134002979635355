import React, { FC } from 'react';
import styles from './_styles.module.scss';
import WorkDate from '../Works/WorkCardMob/WorkDate';
import JobCardType from '../Candidate/JobDetails/JobCardDetails/JobCardType';

const JobTypeAndDate: FC<{ type: string; date: string }> = ({ type, date }) => {
  return (
    <div className={styles.wrap}>
      <JobCardType type={type} />
      <WorkDate date={date} />
    </div>
  );
};

export default JobTypeAndDate;
