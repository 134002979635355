import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { IInvoiceDetails } from '../../../../core/models/models';

const PurchaseListItem: FC<{ details: IInvoiceDetails }> = ({ details }) => {
  return (
    <li className={styles.purchase_item}>
      <ul className={styles.details_list}>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Date of purchase:</h4>
          {details.created_at}
        </li>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Package:</h4>
          {details.package}
        </li>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Number of shortlist:</h4>
          {details.shortlists_amount}
        </li>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Bump:</h4>
          {details.posts_amount}
        </li>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Validity:</h4>
          {details.validity_days}
        </li>
        <li className={styles.details_item}>
          <h4 className={styles.details_heading}>Amount:</h4>
          {details.total_price}
        </li>
      </ul>
      <div>{details.downloadBtn}</div>
    </li>
  );
};

export default PurchaseListItem;
