import React, { FC } from 'react';
import { InputLabel, InputLabelProps, styled } from '@mui/material';

const ThemeInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '18px',
  letterSpacing: '0.8px',
  top: '-12px',
}));

const UIInputLabelLogin: FC<InputLabelProps> = ({ children, ...props }) => {
  return (
    <ThemeInputLabel shrink={true} {...props}>
      {children}
    </ThemeInputLabel>
  );
};

export default UIInputLabelLogin;
