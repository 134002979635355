import { useRouter } from '../../core/hooks/useRouter';
import React, { useLayoutEffect } from 'react';

const ScrollTopWrapper: React.FC<{
  children: React.PropsWithChildren<any>;
}> = ({ children }) => {
  const { location } = useRouter();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export default ScrollTopWrapper;
