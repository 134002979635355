import React, { FC, useEffect } from 'react';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import styles from './_styles.module.scss';
import Icon, { IconType } from '../UIIcon/UIIcon';

interface UINavLinkProps {
  goTo: string;
  title: string;
  icon: IconType;
  iconClassName?: string;
  className?: string;
  activeClassName?: string;
}

const UIDrawerNavLink: FC<UINavLinkProps> = ({
  goTo,
  title,
  icon,
  iconClassName,
  className,
  activeClassName,
}) => {
  const match = useMatch({
    path: goTo,
    end: goTo.length === 1,
  });

  useEffect(() => {
    // console.log('activeClassName', activeClassName);
    // console.log('match', match);
  }, []);

  return (
    <NavLink
      to={goTo}
      className={({ isActive }) => {
        return `${styles.drawer_link} ${
          isActive && activeClassName ? activeClassName : ''
        } ${match && styles.drawer_link_active} ${className || ''}`;
      }}
    >
      <Icon name={icon} className={`${styles.icon} ${iconClassName || ''}`} />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <span about={title} className={styles.span}>
        {title}
      </span>
    </NavLink>
  );
};

export default UIDrawerNavLink;
