import React, { FC, InputHTMLAttributes } from 'react';
import './UISearchInput.scss';
import cn from 'classnames';

interface UIInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  placeholder?: string;
  value: string;
  // input onChange callback
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UISearchInput: FC<UIInputProps> = ({
  name,
  label,
  placeholder,
  ...rest
}) => {
  return (
    <div className='input-wrapper'>
      <label className='input-heading' htmlFor={name}>
        {label}
      </label>
      <div className='input'>
        <input
          className={'Input'}
          id={name}
          type='text'
          placeholder={placeholder}
          {...rest}
        />
        <div
          className={cn({
            input__icon: true,
          })}
        />
      </div>
    </div>
  );
};

export default UISearchInput;
