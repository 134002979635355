import React, { FC } from 'react';
import './UIRadioButton.scss';

interface UIRadioButtonProps {
  label: string;
  id: string;
  checked?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UIRadioButton: FC<UIRadioButtonProps> = ({
  label,
  id,
  name,
  checked,
  onChange,
}) => {
  return (
    <div className='selector'>
      <div className='selector__label'>
        <span>{label}</span>
      </div>
      <div className='selector__list'>
        <div className='selector__item'>
          <input
            type='radio'
            value={'1'}
            id={`${id}-radio1`}
            name={name || id}
            className='selector__item--radio selector__item--radio-left'
            checked={checked}
            onChange={onChange}
          />
          <label htmlFor={`${id}-radio1`} className='selector__item--label'>
            Yes
          </label>
        </div>
        <div className='selector__item'>
          <input
            type='radio'
            value={'0'}
            id={`${id}-radio2`}
            name={name || id}
            checked={!checked}
            className='selector__item--radio selector__item--radio-right'
            onChange={onChange}
          />
          <label htmlFor={`${id}-radio2`} className='selector__item--label'>
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default UIRadioButton;
