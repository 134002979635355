import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../core/hooks/reduxHooks';
import { useParams, useSearchParams } from 'react-router-dom';
import PriceCard from '../PackagePriceCard/PriceCard';
import styles from './_styles.module.scss';
import StripeContainer from './StripeContainer';
import {
  getSubscriptionPackage,
  getSubscriptionPackages,
} from '../../../../redux/thunk/subscription';
import { type } from 'os';
import PaymentFormBumpShortlist from '../PaymentFormBumpShortlist/PaymentFormBumpShortlist';
import { setPurchaseType } from '../../../../redux/reducers/ui/SubscriptionPackagesSlice';

interface QueryParams {
  origin: string;
  item: string;
  item_id: string;
  user_id: string;
  assignment_id: string;
}

export default function PaymentForm() {
  const { packages, selectedPackage, purchaseType } = useAppSelector(
    state => state.subscriptionPackagesReducer
  );
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector(state => state.userTokenReducer);

  const getQueryParam = (key: keyof QueryParams): string | null =>
    searchParams.get(key);

  // Отримуємо параметри з типізацією
  const originType = getQueryParam('origin') || '';
  const item = getQueryParam('item');
  const itemId = getQueryParam('item_id');
  const userId = getQueryParam('user_id') ?? user_id ?? '';

  useLayoutEffect(() => {
    if (itemId) {
      dispatch(getSubscriptionPackage(itemId));
    }

    dispatch(setPurchaseType(item));
  }, []);

  return item === 'package' || purchaseType === 'subscription' ? (
    <div className={styles.payment}>
      {selectedPackage?.package_id === itemId ? (
        <>
          <div className={styles.payment_package}>
            <PriceCard
              key={selectedPackage.package_id}
              user_id={userId}
              package_id={selectedPackage.package_id}
              title={selectedPackage.name}
              price={selectedPackage.price}
              posting={selectedPackage.number_of_posting}
              shortlist={selectedPackage.number_of_shortlist}
              valid={selectedPackage.validity_days}
              disableBtn={false}
            />
          </div>

          <StripeContainer
            paymentIntentForm={{
              user_id: userId,
              subscription: {
                quantity: 1,
                item_id: selectedPackage.package_id,
              },
              origin: originType,
            }}
          />
        </>
      ) : (
        <div />
      )}
    </div>
  ) : (
    <PaymentFormBumpShortlist />
  );
}
