import React from 'react';

const WorkDate: React.FC<{ date: string; className?: string }> = ({
  date,
  className,
}) => {
  return <span className={`card__date ${className || ''}`}>{date}</span>;
};

export default WorkDate;
