import React, { FC } from 'react';
import { openModal } from '../../../redux/reducers/ui/ModalDialogSlice';
import { useAppDispatch } from '../../../core/hooks/reduxHooks';

const UIRegisterLink: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <button
      onClick={() => dispatch(openModal('registration'))}
      type={'button'}
      className='auth__register-link'
    >
      Register here
    </button>
  );
};

export default UIRegisterLink;
