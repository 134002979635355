import React, { FC, useState } from 'react';
import styles from './_styles.module.scss';

interface AccordionProps {
  questionsAnswers: {
    question: string;
    answer: string;
  }[];
}

const Accordion: FC<AccordionProps> = ({ questionsAnswers }) => {
  const [isSelected, setSelected] = useState<number | null>(0);

  const toggle = (i: number): any => {
    if (i === isSelected) {
      return setSelected(null);
    }

    setSelected(i);
  };

  return (
    <div className={styles.faq}>
      <h1 className={styles.faq__title}>FAQ</h1>
      <ul className={styles.accordion}>
        {questionsAnswers.map((item, i) => (
          <li key={i} className={styles.accordion_item}>
            <div className={styles.accordion_title} onClick={() => toggle(i)}>
              <h2 className={styles.question}>{item.question}</h2>
              <span className={isSelected === i ? styles.active : ''}>+</span>
            </div>
            <div
              className={`${styles.accordion_content} ${
                isSelected === i ? styles.show : ''
              }`}
            >
              {item.answer}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Accordion;
