import React, { FC } from 'react';
import styles from './_styles.module.scss';
import { IJobStatus } from '../../../core/models/models';

const JobStatus: FC<{ status: IJobStatus; className?: string }> = ({
  status,
  className,
}) => {
  return (
    <span
      className={`${styles.status} ${className || ''} ${
        status === 'open' && styles.open
      }`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

export default JobStatus;
