import React, { FC } from 'react';
import './UIButtonLink.scss';
import Icon, { IconType } from '../UIIcon/UIIcon';

type UIButtonProps = {
  disabled?: boolean;
  title: string;
  icon?: IconType;
  iconDirection?: 'left' | 'right';
  classModificator?: string;
  callback: () => void;
};

const UIDrawerButtonLink: FC<UIButtonProps> = ({
  title,
  classModificator = '',
  icon,
  iconDirection,
  disabled = false,
  callback,
}) => {
  return (
    <button
      type='button'
      className={`Button__link ${classModificator || ''}`}
      disabled={disabled}
      onClick={() => callback()}
    >
      {icon && iconDirection === 'left' && <Icon name={icon} />}
      <p className='Button__link--title'>{title}</p>
      {icon && iconDirection === 'right' && <Icon name={icon} />}
    </button>
  );
};

export default UIDrawerButtonLink;
