import React from 'react';
import EmployeeHead from '../../components/Employer/Home/EmployerHead/EmployeeHead';
import WorkSteps from '../../components/Employer/Home/WorkSteps/WorkSteps';
import WhyUs from '../../components/Employer/Home/WhyUs/WhyUs';
import FaqSection from '../../components/Employer/Home/FaqSection/FaqSection';

const EmployerHomePage: React.FC = () => (
  <>
    <EmployeeHead />
    <WorkSteps />
    <WhyUs />
    <FaqSection />
  </>
);

export default EmployerHomePage;
