import React, { FC } from 'react';
import styles from './_styles.module.scss';
import WorkCardMob from '../WorkCardMob/WorkCardMob';
import { IAvailableWorkDetails } from '../../../core/models/models';

const SearchWorkCardListMob: FC<{ assignments: IAvailableWorkDetails[] }> = ({
  assignments,
}) => {
  return (
    <div className={styles.work_list_mob}>
      {assignments?.map(work => (
        <WorkCardMob key={work.assignment_id} work={work} />
      ))}
    </div>
  );
};

export default SearchWorkCardListMob;
