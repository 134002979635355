import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { CandidateHomePage } from '../pages/candidate/CandidateHomePage';
import CandidateSearchPage from '../pages/candidate/CandidateSearchPage';
import CandidateProfile from '../pages/candidate/CandidateProfile';
import EmployerHomePage from '../pages/employer/EmployerHomePage';
import EmployerLayout from '../containers/EmployerLayout/EmployeeLayout';
import AdminLayout from '../containers/AdminLayout';
import CandidateLayout from '../containers/CandidateLayout/CandidateLayout';
import EmployerPricePage from '../pages/employer/EmployerPricePage';
import PriceCardListPage from '../components/Employer/Prices/PriceCardList/PriceCardListPage';
import PaymentForm from '../components/Employer/Prices/PaymentForm/PaymentForm';
import PostJob from '../pages/admin/PostJob';
import EmployerProfilePage from '../pages/employer/EmployerProfilePage';
import EmployerPostJobPage from '../pages/employer/EmployerPostJobPage';
import EmployerPurchaseHistoryPage from '../pages/employer/EmployerPurchaseHistoryPage';
import PostJobSection from '../components/Employer/PostJob/PostJobSection';
import Drafts from '../components/Employer/PostJob/Drafts/Drafts';
import PendingApprovalPosts from '../components/Employer/PostJob/PendingApprovalPosts/PendingApprovalPosts';
import EmployerManageJobsPage from '../pages/employer/EmployerManageJobsPage';
import Invoices from '../pages/admin/Invoices';
import ScrollTopWrapper from '../containers/ScrollTopWrapper';
import ManageJobs from '../components/Employer/ManageJobs/ManageJobs';
import ViewJobMobile from '../components/Employer/ManageJobs/ViewJobMobile/ViewJobMobile';
import CreateInvoice from '../pages/admin/CreateInvoice';
import ViewPendingApprovalJobMob from '../components/Employer/PostJob/PendingApprovalPosts/ViewPendingApprovalJobMob/ViewPendingApprovalJobMob';
import CandidateJobDetailsMob from '../pages/candidate/CandidateJobDetailsMob';
import PaymentFormBumpShortlist from '../components/Employer/Prices/PaymentFormBumpShortlist/PaymentFormBumpShortlist';
import { useAppSelector } from '../core/hooks/reduxHooks';
import ViewAssignments from '../pages/admin/ViewAssignments';
import NavigationLayout from '../containers/NavigationLayout';
import { navigationList } from '../core/constants/config';
import VerifyEmailPage from '../containers/VerifyEmailPage/VerifyEmailPage';
import RequireCompleteProfile from '../containers/CandidateLayout/RequireCompleteProfile';
import CompletionPage from '../pages/employer/CompletionPage';

const initialAdminRoutePath = '/admin/jobs/post';

const Navigation: React.FC = () => {
  const { isAdmin } = useAppSelector(state => state.userTokenReducer);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const adminPathName = pathname.startsWith('/admin');

    if (isAdmin && !adminPathName) {
      // console.log('admin');
      navigate('/admin', { replace: true });
    }

    if (!isAdmin && adminPathName) {
      // console.log('candidate');
      navigate('/');
    }
  }, [isAdmin, pathname]);

  return (
    <ScrollTopWrapper>
      <Routes>
        <Route
          path='/'
          element={
            <CandidateLayout>
              <Outlet />
            </CandidateLayout>
          }
        >
          <Route
            path='email/verify/:emailToken'
            element={<VerifyEmailPage type={'emailVerify'} />}
          />
          <Route
            path='email/unsubscribe/jobs/:emailToken'
            element={<VerifyEmailPage type={'unsubscribeJobs'} />}
          />
          <Route
            path='/email/unsubscribe/candidates/:emailToken'
            element={<VerifyEmailPage type={'unsubscribeCandidate'} />}
          />
          <Route index element={<CandidateHomePage />} />
          <Route
            path='search'
            element={
              <RequireCompleteProfile>
                <CandidateSearchPage />
              </RequireCompleteProfile>
            }
          />
          <Route path=':id/job-details' element={<CandidateJobDetailsMob />} />
          <Route path='profile' element={<CandidateProfile />} />
        </Route>

        <Route
          path='/employer'
          element={
            <EmployerLayout>
              <Outlet />
            </EmployerLayout>
          }
        >
          <Route index element={<EmployerHomePage />} />
          <Route path='post-jobs' element={<EmployerPostJobPage />}>
            <Route index element={<PostJobSection />} />
            {/*<Route path='drafts' element={<Drafts />} />*/}
            <Route path='pending-approval' element={<PendingApprovalPosts />} />
            <Route
              path='pending-approval/view-job/:jobId'
              element={<ViewPendingApprovalJobMob />}
            />
          </Route>
          <Route path='manage-jobs' element={<EmployerManageJobsPage />}>
            <Route index element={<ManageJobs />} />
            <Route path={'view-job/:jobId'} element={<ViewJobMobile />} />
          </Route>
          <Route path='price' element={<EmployerPricePage />}>
            <Route index element={<PriceCardListPage />} />
            <Route
              path={'purchase/completion/:originType'}
              element={<CompletionPage />}
            />
            <Route path={'purchase'} element={<PaymentForm />} />
          </Route>
          <Route path='profile' element={<EmployerProfilePage />} />
          <Route
            path='purchase-history'
            element={<EmployerPurchaseHistoryPage />}
          />
        </Route>

        <Route
          path='/admin'
          element={
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          }
        >
          <Route index element={<Navigate to={initialAdminRoutePath} />} />
          <Route path='jobs'>
            <Route path='post' element={<PostJob />} />
            <Route
              element={
                <NavigationLayout navigationList={navigationList.viewJobs}>
                  <Outlet />
                </NavigationLayout>
              }
            >
              <Route
                index
                element={<ViewAssignments assignmentsType='all' />}
              />
              <Route
                path='staff'
                element={<ViewAssignments assignmentsType='posted_by_staff' />}
              />
            </Route>
            <Route
              element={
                <NavigationLayout navigationList={navigationList.approveJobs}>
                  <Outlet />
                </NavigationLayout>
              }
            >
              <Route
                path={'approve'}
                element={<ViewAssignments assignmentsType='on_review' />}
              />
              <Route
                path='pending'
                element={<ViewAssignments assignmentsType='pending' />}
              />
              <Route
                path='banned'
                element={<ViewAssignments assignmentsType='content_review' />}
              />
            </Route>
          </Route>
          <Route
            path='invoices'
            element={
              <NavigationLayout navigationList={navigationList.invoices}>
                <Outlet />
              </NavigationLayout>
            }
          >
            <Route index element={<Invoices />} />
            <Route path='new' element={<CreateInvoice />} />
          </Route>
        </Route>
        {/* 404 Page */}
        <Route
          path='*'
          element={
            <div style={{ margin: '20vh auto', textAlign: 'center' }}>
              <h1>Error 404</h1>
              <h2>Page not found</h2>
            </div>
          }
        />
      </Routes>
    </ScrollTopWrapper>
  );
};

export default Navigation;
