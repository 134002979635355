import React, { FC } from 'react';
import UIGoBack from '../UI/UIGoBack/UIGoBack';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import styles from './_styles.module.scss';

interface MobileHeaderTitleProps {
  title: string;
  role: 'candidate' | 'employer';
  burgerMenu: boolean;
}

const MobileHeaderTitle: FC<MobileHeaderTitleProps> = ({
  title,
  role,
  burgerMenu,
}) => {
  return (
    <div className={styles.wrapper}>
      <UIGoBack />
      <h2>{title}</h2>
      {role === 'candidate' && burgerMenu ? (
        <BurgerMenu routes={'employer'} />
      ) : role === 'employer' && burgerMenu ? (
        <BurgerMenu routes={'employer'} />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MobileHeaderTitle;
