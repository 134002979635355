import { useNavigate, useLocation } from 'react-router-dom';

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const search = new URLSearchParams(location.search);

  return {
    location,
    navigate,
    search,
    pathname,
  };
};
