import React, { FC, useEffect } from 'react';
import styles from './_styles.module.scss';
import Icon from '../../../UI/UIIcon/UIIcon';
// import UIButton from '../../../UI/UIButton/UIButton';
import { Link } from 'react-router-dom';

type PriceCardProps = {
  user_id: string;
  package_id: string;
  title: string;
  price: number;
  posting: number;
  shortlist: number;
  valid: number;
  disableBtn: boolean;
};

const PriceCard: FC<PriceCardProps> = ({
  user_id,
  package_id,
  title,
  price,
  posting,
  shortlist,
  valid,
  disableBtn,
}) => {

  const packageCircle = (pack: string) => {
    const packageNumber = +pack.slice(-1);

    switch (packageNumber) {
      case 1:
        return styles.circle_one;
      case 2:
        return styles.circle_two;
      case 3:
        return styles.circle_three;
      case 4:
        return styles.circle_four;
      case 5:
        return styles.circle_five;
    }
  };

  return (
    <div className={styles.price_card}>
      <div className={styles.head}>
        <Icon
          name={'priceCircle'}
          className={`${packageCircle(title)} ${styles.circle}`}
        />
        <h3 className={styles.head_title}>{title}</h3>
      </div>
      <ul className={styles.description_list}>
        <li className={styles.description_item}>
          <span>{posting}</span> posting
        </li>
        <li className={styles.description_item}>
          Shortlist up to <span>{shortlist}</span> candidates
        </li>
        <li className={styles.description_item}>
          Valid for <span>{valid}</span> days
        </li>
      </ul>

      <div className={styles.purchase}>
        <span className={styles.purchase_price}>${price}</span>

        {disableBtn ? (
          <Link
            className={styles.purchase_btn}
            to={{
              pathname: '/employer/price/purchase',
              search: `?item=package&item_id=${package_id}&user_id=${user_id}&origin=web`,
            }}
          >
            Purchase
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default PriceCard;
