import React from 'react';
import { ReactComponent as logo } from '../../../assets/icons/Logo.svg';
import { ReactComponent as myJobs } from '../../../assets/icons/my-jobs-icon.svg';
import { ReactComponent as myProfile } from '../../../assets/icons/profile-icon.svg';
import { ReactComponent as manage } from '../../../assets/icons/manage.svg';
import { ReactComponent as jobSeeker } from '../../../assets/icons/job-seeker.svg';
import { ReactComponent as shop } from '../../../assets/icons/shop.svg';
import { ReactComponent as price } from '../../../assets/icons/drw_price.svg';
import { ReactComponent as purchaseHistory } from '../../../assets/icons/purchase-history.svg';
import { ReactComponent as logout } from '../../../assets/icons/logout-icon.svg';
import { ReactComponent as postJob } from '../../../assets/icons/icon-for-employers.svg';
import { ReactComponent as close } from '../../../assets/icons/close-modal.svg';
import { ReactComponent as info } from '../../../assets/icons/profile-info.svg';
import { ReactComponent as details } from '../../../assets/icons/profile-details.svg';
import { ReactComponent as categories } from '../../../assets/icons/profile-categories.svg';
import { ReactComponent as location } from '../../../assets/icons/profile-location.svg';
import { ReactComponent as notification } from '../../../assets/icons/profile-notif.svg';
import { ReactComponent as priceCircle } from '../../../assets/icons/price_circle.svg';
import { ReactComponent as articleApprove } from '../../../assets/icons/article-approve.svg';
import { ReactComponent as book } from '../../../assets/icons/book.svg';
import { ReactComponent as telegram } from '../../../assets/icons/telegram-icon.svg';
import { ReactComponent as calendar } from '../../../assets/icons/calendar.svg';
import { ReactComponent as status } from '../../../assets/icons/status_empl_logo.svg';
import { ReactComponent as arrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as pointDown } from '../../../assets/icons/point-down.svg';
import { ReactComponent as check } from '../../../assets/icons/active-check.svg';
import { ReactComponent as saveFlag } from '../../../assets/icons/save_flag.svg';
import { ReactComponent as plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as minus } from '../../../assets/icons/minus.svg';
import { ReactComponent as bumpIcon } from '../../../assets/icons/bump_icon.svg';
import { ReactComponent as shortlistIcon } from '../../../assets/icons/shortlist_icon.svg';
import { ReactComponent as phone } from "../../../assets/icons/phone.svg";

export const iconTypes = {
  logo,
  myJobs,
  myProfile,
  manage,
  jobSeeker,
  shop,
  price,
  purchaseHistory,
  logout,
  postJob,
  close,
  info,
  details,
  categories,
  location,
  notification,
  priceCircle,
  articleApprove,
  book,
  telegram,
  calendar,
  status,
  arrowDown,
  pointDown,
  check,
  saveFlag,
  plus,
  minus,
  bumpIcon,
  shortlistIcon,
  phone,
};

export type IconType = keyof typeof iconTypes;

const Icon: React.FC<{
  name: IconType;
  className?: string;
  [x: string]: any;
}> = ({ name, className = '', ...props }) => {
  const IconComponent = iconTypes[name] || null;

  return IconComponent && <IconComponent className={className} {...props} />;
};

export default Icon;
