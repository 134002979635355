import React from 'react';
import style from './_index.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input: React.FC<InputProps> = ({ label = '', ...rest }) => {
  return (
    <div className={style.wrap}>
      {label && <label htmlFor={rest?.id}>{label}</label>}
      <input {...rest} />
    </div>
  );
};

export default Input;
