import React, { FC, Fragment } from 'react';
import { Drawer } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../core/hooks/reduxHooks';
import { drawerSlice } from '../../redux/reducers/ui/DrawerSlice';

// type NavigationLinksTypes = {
//   name: string;
//   href: string;
// };

// type DrawerMenuProps = {
//   navigationLinks: NavigationLinksTypes[];
// };

const DrawerMenu: FC = ({ children }) => {
  const { isOpened } = useAppSelector(state => state.drawerReducer);
  const dispatch = useAppDispatch();
  const { openDrawer, closeDrawer } = drawerSlice.actions;

  /*eslint-disable */
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      open ? dispatch(openDrawer()) : dispatch(closeDrawer());
    };
  /*eslint-enable */

  return (
    <Fragment key={'rightDrawer'}>
      <Drawer
        anchor={'right'}
        open={isOpened}
        onClose={() => dispatch(closeDrawer())}
        sx={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <div className='drawer'>{children}</div>
      </Drawer>
    </Fragment>
  );
};

export default DrawerMenu;
